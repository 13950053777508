export function generateRadioScript(station) {
  return `{
    "mapi": "https://mapi.radioplayer.cloud/api/be/",
    "cm": "https://cm.radioplayer.cloud/056/",
    "al": "https://fh-be.radioplayer.org/analytics.html",
    "np": "https://core-search.radioplayer.cloud/056/qp/",
    "qp": "https://core-search.radioplayer.cloud/056/qp/",
    "rpLogo": "/luister/radioplayer.svg",
    "id": "${station.maRadioId}",
    "fallback": [
      {
        "url": "${station.fallback}",
        "format": "mp3"
      }
    ],
    "stationLogo": "${station.thumbnail}",
    "stationUrl": "${station.stationUrl}",
    "theme": "LIGHT",
    "themeColour": "${station.color}",
    "widgets": [""],
    "widgetOverrides": "",
    "disableCookieBanner": "true"
  }`
}
