import React, { createContext } from "react"

const RadioPlayerApiContext = createContext({})
const { Provider } = RadioPlayerApiContext

export function RadioPlayerApiProvider({ children }) {
  return (
    <Provider
      value={
        {
          // FILL
        }
      }
    >
      {children}
    </Provider>
  )
}

export default RadioPlayerApiContext
