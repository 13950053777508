import React from "react"
import { Router } from "@reach/router"
import Player from "../components/RadioPlayer/Player"

const RadioPlayer = () => {
  return (
    <Router>
      <Player path="luister/:stationSlug" />
    </Router>
  )
}

export default RadioPlayer
