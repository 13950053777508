import React from "react"
import { Helmet } from "react-helmet"
import { RadioPlayerApiProvider } from "src/context/RadioPlayerContext"
import "./Container.scss"

export default function RadioPlayerContainer({ children, page }) {
  return (
    <RadioPlayerApiProvider>
      <div>
        <Helmet>
          <link
            rel="preload"
            as="font"
            crossOrigin
            type="font/woff2"
            href="https://player.radio/latest/assets/sintony-v7-latin-700.woff2"
          />
          <meta charSet="utf-8" />
          <title>{page || ''} | Play Nostalgie</title>
          <link
            rel="stylesheet"
            href="https://player.radio/latest/radioplayer.css"
          />
        </Helmet>
        {children}
      </div>
    </RadioPlayerApiProvider>
  )
}
