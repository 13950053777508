import React, { useCallback, useEffect, useRef, useState } from "react"
import withRadioPlayerLayout from "../../hoc/withRadioPlayerLayout"
import "./Player.scss"
import RadioPlayerNostalgie from "./Nostalgie"
import { generateRadioScript } from "../../helpers/RadioPlayer"
import hero from "../../resources/images/hero-profile.jpg"
import { Helmet } from "react-helmet"
import { getPublicationsEndpoint } from "../../services/preprApi"
import { formUrlEncoded } from "../../helpers/Url"
import { formatCurrentProgram, formatStationData } from "../../helpers/Prepr"
import { GUIDE_ID, STATION_MODEL_ID } from "../../constants/models"
import { get } from "axios"
import { getGuideEndpoint } from "../../services/legacyApi"


const useMutationObserver = (
  ref,
  callback,
  options = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  }
) => {
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
      return () => observer.disconnect();
    }
  }, [callback, options, ref]);
};

function Player({ stationSlug }) {
  const mutationRef = React.useRef();
  const [station, setStation] = useState(null)
  const [currentShow, setCurrentShow] = useState(null)
  const [headers] = useState({
    Authorization: `Bearer ${process.env.PREPR_TOKEN}`,
  })
  const startTime = useRef(null);

  const getStationPublicationBySlug = useCallback(async(slug) => {
    const url = {
      slug: [
        {
          eq: slug,
        },
      ],
      fields:
      "items{image_website{cdn_files},image_mobile{cdn_files}},model",
      model: {
        id: STATION_MODEL_ID,
      },
    }
    const response = await get(
      getPublicationsEndpoint(formUrlEncoded(url, false)),
      { headers }
    )
    return formatStationData(response.data)
  }, [headers]);

  const fetchStation = useCallback(() => {
    getStationPublicationBySlug(stationSlug).then((station) => {
      setStation(station)
    })
  }, [getStationPublicationBySlug, stationSlug]);

  const getCurrentProgram = useCallback(async () => {
    const response = await get(getGuideEndpoint(GUIDE_ID), { headers })
    return formatCurrentProgram(response.data)
  }, [headers]);

  const fetchCurrenProgram = useCallback(() => {
    getCurrentProgram().then((show) => {
      setCurrentShow(show)
    })
  }, [getCurrentProgram]);

  useEffect(() => {
    fetchStation();
    fetchCurrenProgram();
  }, [fetchCurrenProgram, fetchStation, stationSlug])

  const playerAnalytics = (event, station, currentShow, durationInSeconds) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: event,
      channel: station?.title,
      program: currentShow?.showName,
      durationInSeconds:durationInSeconds
    })
  }

  const handlePlayerAnalytics = useCallback((type) => {
    if (type === 'play') {
      playerAnalytics('player_play', station, currentShow, 0);
    } else if (type === 'pause') {
      const now = new Date();
      const timeDiff = now.getTime() - new Date(startTime.current).getTime();
      const durationInSeconds = timeDiff / 1000;
      playerAnalytics(
        'player_stop',
        station,
        currentShow,
        durationInSeconds,
      );
    }
  }, [station, currentShow, startTime]);

  window.addEventListener('beforeunload', function (e) {
    let audio = document.querySelector("audio");
    if(audio) {
      if(audio.paused) {
        return;
      } else {
        audio.pause();
        e.preventDefault();
        e.returnValue = '';
      }
    }
});


  const audioEventListener = useCallback((event) => {
    let audio = document.querySelector("audio");
    const found = event.find(subArray => [...subArray.addedNodes]?.find((node) => node.nodeName.toLowerCase() === 'audio'));
    if(audio && found) {
      audio.addEventListener("play", () => {
        startTime.current = new Date();
        handlePlayerAnalytics('play')
      });
      audio.addEventListener("pause", () => {
        handlePlayerAnalytics('pause')
      });
    }
  }, [handlePlayerAnalytics]);

  useMutationObserver(mutationRef, audioEventListener);

  return (
    <>
      {station &&
      <>
        <Helmet>
          <meta
            property="og:title"
            content={`${
              station ? station.title : "luister"
            } | Nostalgie België`}
          />
          <meta
            property="og:image"
            content={(station ? station.image : null) || hero}
          />
          <meta
            property="og:description"
            content={station ? station.title : "luister"}
          />
          <link rel="preconnect" href="https://mapi.radioplayer.cloud/api/be/" />
          <link rel="preconnect" href="https://core-search.radioplayer.cloud/056/qp/" />
          <link
            rel="preconnect"
            href="https://search.maradio.be/"
            crossOrigin="use-credentials"
          />
          <link
            rel="preconnect"
            href="https://cookie.maradio.be/"
            crossOrigin="use-credentials"
          />
          <script
            type="module"
            src="https://player.radio/latest/radioplayer.js"
          ></script>
          <script
            noModule
            defer
            src="https://player.radio/latest/radioplayer.legacy.js"
          ></script>
        </Helmet>
        <div data-widget-radioplayer ref={mutationRef}>
            <script
                type="application/json"
                dangerouslySetInnerHTML={{ __html: generateRadioScript(station) }}
            />
        </div>
        <div className="player-content">
          <RadioPlayerNostalgie apiId={station.id} radio={station} />
        </div>
      </>
      }
    </>
  );
}

export default withRadioPlayerLayout(Player, {
  page: "Radio Player",
})
