import React from "react"
import RadioPlayerLayout from "src/components/Layout/RadioPlayerContainer"

export default function withGuestLayout(Component, layoutProps) {
  return function WrapperComponent(props) {
    return (
      <RadioPlayerLayout {...layoutProps}>
        <Component {...props} />
      </RadioPlayerLayout>
    )
  }
}
